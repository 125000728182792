import './_circle.scss';

function Circle(props) {
  let {
    circleOffsetLeft,
    circleOffsetTop,
    circleColor,
    animate,
  } = props;
  const classNames = ['circle'];

  if(!circleOffsetLeft) {
    circleOffsetLeft = '0';
  }

  if(!circleOffsetTop) {
    circleOffsetTop = '0';
  }

  if(animate) {
    classNames.push('animate');
  }

  let circleStyle = {
    left: circleOffsetLeft,
    top: circleOffsetTop
  }

  if(circleColor) {
    circleStyle = {
      ...circleStyle,
      ...{
        backgroundColor: circleColor
      }
    }
  }

  return <div className='circleWrapper'>
    <div className={classNames.join(' ')} style={circleStyle}/>
  </div>;
}

export default Circle;
