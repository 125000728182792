import {
  Component
} from 'react';
import Icon from '../../Atoms/Icon/icon';
import Typography from '../../Atoms/Typography/typography';

import './_switchLanguage.scss';

class SwitchLanguage extends Component {
  render() {
    const language = this.props.language;
    const page = this.props.page ? `/${this.props.page}` : '';

    return <div
    className='switchLanguage'
    >
      <div
      className='switcher'
      >
        <Typography
        component='text'
        linkTo={`/de${page}`}
        >
          <Icon
          icon='flag_de'
          width='50'
          active={language === 'de' ? true : false}
          />
        </Typography>
      </div>
      <div
      className='switcher'
      >
        <Typography
        component='text'
        linkTo={`/en${page}`}
        >
          <Icon
          icon='flag_en'
          width='50'
          active={language === 'en' ? true : false}
          />
        </Typography>
      </div>
    </div>;
  }
}

export default SwitchLanguage;
