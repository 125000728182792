import {
  Component
} from 'react';

import Grid from '../Grid/grid';
import Icon from '../../Atoms/Icon/icon';
import Typography from '../../Atoms/Typography/typography';

import './_serviceList.scss';

class ServiceList extends Component {
  render() {
    const {
      data
    } = this.props;
    const returnListElements = [];

    Object.keys(data).forEach(key => {
      returnListElements.push(
        <Grid
        item
        columns={3}
        spacing={0}
        key={key}
        >
          <div
          className='serviceListItem'
          >
            <div
            className='serviceIcon'
            >
              <Typography
              component='text'
              textAlign='center'
              >
                <Icon
                icon={data[key].icon}
                width={data[key].iconWidth}
                />
              </Typography>
            </div>
            <div
            className='serviceName'
            >
              <Typography
              component='text'
              textAlign='center'
              >
                {data[key].name}
              </Typography>
            </div>
          </div>
        </Grid>
      );
    });

    return returnListElements;
  }
}

export default ServiceList;
