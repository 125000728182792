import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import SwitchLanguage from '../../Molecules/SwitchLanguage/switchLanguage';

import translations from './translations.json';
import translate from '../../../Utils/translate';

function Imprint(props) {
  const {
    language
  } = props;

  return (
    <Grid
    wrapper
    >
      <Typography
      component='h1'
      textAlign='center'
      linkTo={`/${language}`}
      >
        <RotatingText
          // animationName='colorFadePrimaryColor'
          defaultColor='primary'
          rotationSpeed={6000}
          staticText='|'
          staticTextSize={130}
          staticTextPosition='right'
          staticTextBlinking={true}
          words={['vomJulian']}
        />
      </Typography>
      <Typography
      textAlign='center'
      component='h2'
      >
        {translate(translations, language, 'imprint')}
      </Typography>
      <Typography
      textAlign='center'
      component='h2'
      >
        Angaben gem. § 5 TMG
      </Typography>
      <Typography
      textAlign='center'
      component='H4'
      >
        {translate(translations, language, 'name')}
      </Typography>
      <Typography
      textAlign='center'
      component='span'
      >
        Giuliano Demontis
      </Typography>
      <Typography
      textAlign='center'
      component='H4'
      >
        {translate(translations, language, 'address')}
      </Typography>
      <Typography
      textAlign='center'
      component='span'
      >
        Kyffhäuserstr. 11
      </Typography>
      <Typography
      textAlign='center'
      component='span'
      >
        50674 Köln
      </Typography>
      <Typography
      textAlign='center'
      component='h4'
      >
        {translate(translations, language, 'email')}
      </Typography>
      <Typography
      textAlign='center'
      component='span'
      linkType='eMail'
      linkTo={`mailto:giuliano.demontis@vomjulian.com?subject=Hey vomJulian!&body=Hey vomJulian!`}
      >
        giuliano.demontis@vomjulian.com
      </Typography>
      <Grid
        item
        columns={12}
        >
          <SwitchLanguage page={'imprint'} language={language}/>
        </Grid> 
    </Grid>
  );
}

export default Imprint;
