import { Component } from 'react';
import { Link } from 'react-router-dom';
import superagent from 'superagent';

import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import Avatar from '../../Molecules/Avatar/avatar';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import Input from '../../Atoms/Input/input';
import Button from '../../Atoms/Button/button';

class SignIn extends Component {
  state = {
    language: 'en',
    responseBody: false,
    responseErrors: false,
    eMail: '',
    password: '',
    access_token: false,
    refresh_token: false,
    user: false
  }

  componentWillMount() {
    const access_token_from_local_storage = localStorage.getItem("access_token");
    const refresh_token_from_local_storage = localStorage.getItem("refresh_token");

    if(access_token_from_local_storage && refresh_token_from_local_storage) {
      this.setState({
        access_token: access_token_from_local_storage,
        refresh_token: refresh_token_from_local_storage
      });
    }

    // document.addEventListener('keydown', this.keyDown);
  }

  // keyDown(e) {
  //   if (e.key == 'l') {
  //     this.setState({
  //       responseErrors: false,
  //       responseBody: false,
  //       access_token: false,
  //       refresh_token: false
  //     });
  //   }
  // }

  clickButton() {
    const {
      REACT_APP_RECEIVER_API,
      REACT_APP_APP_NAME
    } = process.env;

    const {
      eMail,
      password
    } = this.state;

    const postBody = {
      password: password,
      e_mail: eMail
    }

    superagent.post(`${REACT_APP_RECEIVER_API}/auth/sign-in`)
    .send(postBody)
    .end((err, res) => {
      if(err) {
        console.log(err, res)

        this.setState({
          responseErrors: [err.message],
          responseBody: false,
          access_token: false,
          refresh_token: false
        });

        
      }
      if(err && res.body.errors) {
        console.log(err, res)

        this.setState({
          responseErrors: res.body.errors,
          responseBody: false,
          access_token: false,
          refresh_token: false
        });

        
      }
      if(!err) {
        let access_token;
        let refresh_token;
        if (res.body.data && res.body.data.tokens) {
          access_token = res.body.data.tokens.access_token
          refresh_token = res.body.data.tokens.refresh_token
        }

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);

        this.setState({
          responseErrors: false,
          responseBody: res.body,
          access_token,
          refresh_token
        });
      }
    });
  }

  render() {
    const {
      responseErrors,
      responseBody,
      access_token,
      refresh_token
    } = this.state;
    const returnErrors = [];
    const returnBody = [];

    Object.keys(responseErrors).forEach(key => {
      returnErrors.push(
        <div
        key={`div__${Math.random()}`}
        style={{
          color:'red',
          float: 'none',
          clear: 'both',
          display: 'block',
          width: '100%',
          padding: '5px 0',
          textAlign: 'center'
        }}
        >
          {responseErrors[key]}
        </div>
      );
    });

    if(access_token) {
      const explode_token = access_token.split('.');
      let user = atob(explode_token[1]);
      user = JSON.parse(atob(explode_token[1]));

      const return_roles = [];
      if (user && user.roles) {
        const {
          roles
        } = user;

        Object.keys(roles).forEach(key => {
          return_roles.push(roles[key].role)
        });
      }

      returnBody.push(
        <>
          <Typography
          component='h2'
          textAlign='center'
          >
            Welcome {user.e_mail ? user.e_mail : ''}
          </Typography>
          <Typography
          component='h3'
          textAlign='center'
          >
            Your {return_roles.length === 1 ? 'role is' : 'roles are'} {return_roles ? return_roles.join(' and ') : ''}.
          </Typography>
        </>
      );
    }

    // Object.keys(responseErrors).forEach(key => {
    //   returnBody.push(
    //     <div
    //     style={{
    //       color:'red',
    //       float: 'none',
    //       clear: 'both',
    //       display: 'block',
    //       width: '100%',
    //       padding: '5px 0',
    //       textAlign: 'center'
    //     }}
    //     key={`div__${Math.random()}`}
    //     >
    //       {responseErrors[key]}
    //     </div>
    //   );
    // });

    return (
      <Grid
      wrapper
      >
        <Typography
          component='h1'
          textAlign='center'
          linkTo={`/${this.state.language}`}
        >
          <RotatingText
            animationName='colorFadePrimaryColor'
            defaultColor='primary'
            rotationSpeed={6000}
            staticText='|'
            staticTextSize={130}
            staticTextPosition='right'
            staticTextBlinking={true}
            words={['vomJulian']}
          />
        </Typography>      
        <Grid
          item
          columns={12}
        >
          <Grid
            item
            columns={4}
            spacing={20}
            responsiveFullWidth='800'
          >
            <Avatar/>
          </Grid>
          {!access_token ? <>
            <Grid
              item
              columns={12}
              responsiveFullWidth='800'
            >
              <Input
              defaultValue={this.state.eMail}
              placeholder='E-Mail'
              type='e-mail'
              onChange={(e) => this.setState({
                eMail: e.target.value
              })}
              />
            </Grid>
            <Grid
              item
              columns={12}
              responsiveFullWidth='800'
            >
              <Input
              defaultValue={this.state.password}
              placeholder='Password'
              type='password'
              onChange={(e) => this.setState({
                password: e.target.value
              })}
              />
            </Grid>
            <Grid
              item
              columns={12}
              responsiveFullWidth='800'
            >
              <Button
              onClick={(e) => this.clickButton()}
              >
                Send
              </Button>
            </Grid>
          </> : null}
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            {returnErrors}
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            {returnBody ? returnBody : null}
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            {
              this.state.access_token && this.state.refresh_token ?
                <Grid
                  item
                  columns={10}
                  responsiveFullWidth='800'
                >
                  <Button
                  linkTo="/user/profile"
                  >
                    Go to your Profile
                  </Button>
                </Grid>
              : null
            }
            {
            access_token && refresh_token ?
              <Grid
                item
                columns={10}
                responsiveFullWidth='800'
                style={{margin: '10px 0 0'}}
              >
                <Button
                  buttonType='warn'
                  onClick={(e) => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    this.setState({
                      responseBody: false,
                      responseErrors: false,
                      access_token: false,
                      refresh_token: false,
                    });
                  }}
                >
                  Sign out
                </Button>
              </Grid>
            : null 
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SignIn;
