import Score from '../../Atoms/Score/score';
import Divider from '../../Atoms/Divider/divider';
import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import Avatar from '../../Molecules/Avatar/avatar';
import ServiceList from '../../Molecules/ServiceList/serviceList';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import SwitchLanguage from '../../Molecules/SwitchLanguage/switchLanguage';

import professionalSkillsDe from './ProfessionalSkills/de.json';
import professionalSkillsEn from './ProfessionalSkills/en.json';

import servicesDe from './Services/de.json';
import servicesEn from './Services/en.json';

import translations from './translations.json';
import translate from '../../../Utils/translate';

function aboutSection(language) {
  const professionalSkills = language === 'de' ? professionalSkillsDe : professionalSkillsEn;
  const transformedSkills = {};
  const returnSkills = [];

  Object.keys(professionalSkills).forEach(key => {
    const type = professionalSkills[key].type;
    
    if(!transformedSkills[type]) {
      transformedSkills[type] = [];
    }
    
    transformedSkills[type].push({
      name: professionalSkills[key].name,
      rating: professionalSkills[key].rating,
    })
  });
  
  Object.keys(transformedSkills).forEach(type => {
    const innerSkills = [];

    transformedSkills[type].forEach(obj => {
      innerSkills.push(
        <Grid
          item
          columns={12}
          spacing={5}
          key={Math.random()}
        >
          <Typography
            component='h5'
            textAlign='left'
          >
            {obj.name}
          </Typography>
          <Score score={obj.rating}/>
        </Grid>
      );
    });

    returnSkills.push(
      <Grid
        item
        columns={4}
        spacing={20}
        responsiveFullWidth='600'
        key={Math.random()}
      >
        <Typography
          component='h4'
          textAlign='center'
        >
          {type}
        </Typography>
        {innerSkills}
      </Grid>
    );
  });

  const services = language === 'de' ? servicesDe : servicesEn;

  return  <Grid
    item
    columns={12}
    spacing={0}
  >
    <Grid
      item
      columns={12}
      spacing={40}
    >
      <Grid
        item
        columns={12}
      >        
        <Grid
          item
          columns={4}
          spacing={20}
          responsiveFullWidth='800'
        >
          <Avatar/>
        </Grid>
        <Grid
          item
          columns={4}
          responsiveFullWidth='800'
        >
          <Typography
            component='greeting'
            textAlign='center'
          >
            {translate(translations, language, 'hello_iam')}
          </Typography>
          <Typography
            component='subGreeting'
            textAlign='center'
          >
            {translate(translations, language, 'hello_ido')}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        columns={12}
        spacing={40}
      >
        <Grid
        item
        columns={4}
        spacing={0}
        >
          <Typography
            component='caption'
            textAlign='center'
          >
            {translate(translations, language, 'caption')}
          </Typography>
        </Grid>
      </Grid>
      
      <Divider/>

      <Grid
        item
        columns={12}
        spacing={0}
      >
        <Typography
          component='h2'
          textAlign='center'
        >
          {translate(translations, language, 'services')}
        </Typography>
        <ServiceList data={services}/>
      </Grid>
      <Divider/>
    </Grid>
    <Grid
    item
    columns={12}
    spacing={40}
    >
      <Typography
      component='h2'
      textAlign='center'
      >
        {translate(translations, language, 'professional_experience')}
      </Typography>
      {returnSkills}
      {/* <Divider/> */}
    </Grid>
  </Grid>;
}

function About(props) {
  const {
    language
  } = props;

  return (
    <Grid
      wrapper
      maxWidth
    >
      <Typography
        component='h1'
        textAlign='center'
        linkTo={`/${language}`}
      >
        <RotatingText
          // animationName='colorFadePrimaryColor'
          defaultColor='primary'
          rotationSpeed={60000}
          staticText='|'
          staticTextSize={130}
          staticTextPosition='right'
          staticTextBlinking={true}
          words={['vomJulian']}
        />
      </Typography>

    
      <Grid
      item
      columns={12}
      >
        <SwitchLanguage page={'about-me'} language={language}/>
      </Grid>  

      {aboutSection(language)}

      <Grid
        item
        columns={12}
        spacing={20}
      >
        <Typography component='span' textAlign='center' linkTo={`/${language}/imprint`}>
          {translate(translations, language, 'imprint')}
        </Typography>  
      </Grid>
      <Grid
      item
      columns={12}
      >
        <SwitchLanguage page={'about-me'} language={language}/>
      </Grid> 
    </Grid>
  );
}

export default About;
