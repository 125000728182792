import { Component } from 'react';

import './_rotatingText.scss';

/* USAGE */

{/* <RotatingText
  words={['welcome', 'to', 'vomJulian']}
  text='Hi'

  staticText='|'
  staticTextSize={100}
  staticTextPosition='right'
  staticTextBlinking={true}

  scalingAnimation={true}
  scalingAnimationMinimumFontSize={50}
/> */}


class RotatingText extends Component {
  state = {
    countWords: this.props.words.length - 1,
    currentWordIndex: 0,
    currentLetterIndex: 0,
    rotationSpeed: 10000,
    noRotation: false,
  };

  init() {
    const {
      rotationSpeed,
      noRotation
    } = this.props;

    this.setState({
      rotationSpeed: rotationSpeed ? rotationSpeed: 10000,
      noRotation: noRotation ? noRotation : false
    }, () => {
      this.addLetterAnimation();
    });
  }

  componentDidMount() {
    this.init();
  }

  rotateWordsAnimation() {
    clearInterval(this.rotateWords);
    this.addLetterAnimation();

    this.rotateWords = setInterval(() => {
      let currentWordIndex = this.state.currentWordIndex + 1;

      if (this.state.countWords < currentWordIndex) {
        currentWordIndex = 0;
      }

      this.setState({
        currentWordIndex,
        currentLetterIndex: 0
      });

    }, this.state.rotationSpeed);
  }

  addLetterAnimation() {
    clearInterval(this.addLetter);

    const currentLetterIndex = this.state.currentLetterIndex + 1;
    const lengthOfCurrentWord = this.props.words[this.state.currentWordIndex].length;
    console.log(lengthOfCurrentWord)
    console.log(this.state)
    if(this.state.noRotation) {
      this.setState({
        currentLetterIndex: 9
      });

      return;
    }

    if(!this.state.noRotation) {
      this.addLetter = setInterval(() => {
        const currentLetterIndex = this.state.currentLetterIndex + 1;
        const lengthOfCurrentWord = this.props.words[this.state.currentWordIndex].length;
  
        if(lengthOfCurrentWord === currentLetterIndex) {
          this.rotateWordsAnimation();
        }
  
        this.setState({
          currentLetterIndex
        });
      }, 100);
    }
  }

  componentWillUnmount() {
    clearInterval(this.rotateWords);
    clearInterval(this.addLetter);
  }

  generateScalingLetters() {
    const {
      scalingAnimationMinimumFontSize,
      scalingAnimation,
      animationName,
      defaultColor,
      words
    } = this.props;
    const {
      currentLetterIndex,
      currentWordIndex
    } = this.state;
    const currentWord = words[currentWordIndex];

    const currentWordSubstring = currentWord.substring(0, currentLetterIndex);
    const currentWordSubstringLettersArray = currentWordSubstring.split('');
    const returnLetters = [];
    const fullWordLength = currentWord.length;

    const classNames = ['scalingLetter'];
    if(defaultColor) {
      classNames.push(`color__${defaultColor}`);
    }

    if(animationName) {
      classNames.push(`animation__${animationName}`);
    }

    Object.keys(currentWordSubstringLettersArray).forEach(key => {
      const currentLetterKey = parseInt(key) + 1;
      const minTextSizePercentage = scalingAnimationMinimumFontSize ? scalingAnimationMinimumFontSize : 10;
      const currentLetterSize = (
          (100 - minTextSizePercentage) / fullWordLength * currentLetterKey
        ) + minTextSizePercentage;

      let style = {};

      if(scalingAnimation) {
        style = {
          ...style,
          ...{
            fontSize: `${currentLetterSize}%`
          } 
        }
      }

      returnLetters.push(
        <span
          className={classNames.join(' ')}
          style={style}
          key={key}
        >
          {currentWordSubstringLettersArray[key]}
        </span>
      );
    });

    return returnLetters;
  }

  staticTextContainer() {
    const staticTextBlinking = this.generateStaticTextBlinking();
    const staticText = this.generateStaticText();
    const {
      defaultColor,
      staticTextSize
    } = this.props;
    const classNames = ['staticText'];

    if(defaultColor) {
      classNames.push(`color-${defaultColor}`);
    }

    let style = {};

    if(staticTextSize) {
      style = {
        ...style,
        ...{
          fontSize: `${staticTextSize}%`
        } 
      }
    }

    if(staticTextBlinking) {
      classNames.push('blinking');
    }

    return <span
      className={classNames.join(' ')}
      style={style}
    >
      {staticText}
    </span>;
  }

  generateStaticText() {
    let staticText = '|';

    if(this.props.staticText) {
      staticText = this.props.staticText;
    }

    return staticText;
  }

  generateStaticTextPosition() {
    let staticTextPosition = 'left';

    if(this.props.staticTextPosition) {
      staticTextPosition = this.props.staticTextPosition;
    }

    return staticTextPosition;
  }

  generateStaticTextBlinking() {
    let staticTextBlinking = false;

    if(this.props.staticTextBlinking) {
      staticTextBlinking = true;
    }

    return staticTextBlinking;
  }

  render() {
    const staticTextPosition = this.generateStaticTextPosition();
    const returnWords = this.generateScalingLetters();
    const classNames = [
      'rotatingWord'
    ];

    return <span className='rotatingText'>
      {
        this.props.text ?
          <span className='text'>
            {this.props.text}
          </span> : null
      }

      <span className={classNames.join(' ')}>
        {
          staticTextPosition === 'left' && this.props.staticText ? 
            this.staticTextContainer()
            : null
        }

        {returnWords}
        
        {
          staticTextPosition === 'right' && this.props.staticText ? 
            this.staticTextContainer()
            : null
        }        
      </span>
    </span>;
  }
}

export default RotatingText;
