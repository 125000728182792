import { Link } from 'react-router-dom';

import './_squareText.scss';

import Typography from '../Typography/typography';

function SquareText(props) {
  const {
    responsive,
    component,
    children,
    linkType,
    cursor,
    linkTo,
  } = props;
  const classNames = ['squareText'];

  let typographyComponent = 'h1';
  if (component) {
    typographyComponent = component;
  }

  if(cursor) {
    classNames.push('cursor');
  }

  return <div className={classNames.join(' ')}>
    <Typography
    textAlign='center'
    linkTo={linkTo ? linkTo : false}
    linkType={linkType ? linkType : false}
    component={typographyComponent}
    responsive={responsive ? responsive : false}
    >
      {children}
    </Typography>
  </div>;
}

export default SquareText;
