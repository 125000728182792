import './_avatar.scss';

function Avatar(props) {
  return <div
    className='avatar'
  >
    <div className='head'>
      <div className='hair'/>
      <div className='hairStrand'/>
      <div className='eye eyeLeft'/>
      <div className='eye eyeRight'/>
      <div className='hairStrand'/>
      <div className='circle1'/>
      <div className='circle2'/>
      <div className='circle3'/>
      <div className='glass glassLeft'>
        <div className='shine'/>
      </div>
      <div className='glassBetween'/>
      <div className='glass glassRight'>
        <div className='shine'/>
      </div>
      <div className='ear earLeft'/>
      <div className='ear earRight'/>
    </div>
    <div className='neck'/>
    <div className='body'>
      <div className='dot'/>
    </div>
  </div>;
}

export default Avatar;
