import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import Cloud from '../../Molecules/Cloud/cloud';
import Avatar from '../../Molecules/Avatar/avatar';
import RotatingText from '../../Molecules/RotatingText/rotatingText';

function FallBack(props) {
  return (
    <Grid
    wrapper
    >
      <Typography
        component='h1'
        textAlign='center'
        linkTo={`/${props.language}`}
      >
        <RotatingText
          animationName='colorFadePrimaryColor'
          defaultColor='primary'
          rotationSpeed={6000}
          staticText='|'
          staticTextSize={130}
          staticTextPosition='right'
          staticTextBlinking={true}
          words={['vomJulian']}
        />
      </Typography>      
      <Grid
        item
        columns={12}
      >        
        <Grid
          item
          columns={4}
          spacing={20}
          responsiveFullWidth='800'
        >
          <Avatar/>
        </Grid>
        <Grid
          item
          columns={4}
          responsiveFullWidth='800'
        >
          <Cloud>
            <Typography
            component='h2'
            textAlign='center'
            >
              404
            </Typography>
            <Typography
              component='h3'
              textAlign='center'
              defaultColor='dark'
            >
              Nothing here
            </Typography>
            <Typography
            component='h4'
            textAlign='center'
            linkTo={`/${props.language}`}
            >
              Go back to the main page
            </Typography>
          </Cloud>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FallBack;
