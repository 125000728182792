import { track } from '../../../Utils/track';

import './_typography.scss';

function Typography(props) {
  const {
    responsive,
    animation,
    buttonText,
    defaultColor,
    className,
    linkType,
    linkTo,
  } = props;
  let component = null;
  let children = props.children;
  const classNames = ['typography'];

  if (typeof children === 'string') { 
    console.log(typeof children, children)
  }

  if (className) {
    classNames.push(className);
  }

  if (defaultColor) {
    classNames.push(`defaultColor__${defaultColor}`);
  }

  if(props.component.toLowerCase() === 'h1') {
    component = <h1>
      {props.children}
    </h1>
  }

  if(props.component.toLowerCase() === 'h2') {
    component = <h2>
      {props.children}
    </h2>
  }

  if(props.component.toLowerCase() === 'h3') {
    component = <h3>
      {props.children}
    </h3>
  }

  if(props.component.toLowerCase() === 'h4') {
    component = <h4>
      {props.children}
    </h4>
  }

  if(props.component.toLowerCase() === 'h5') {
    component = <h5>
      {props.children}
    </h5>
  }

  if(props.component.toLowerCase() === 'span') {
    component = <span>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'greeting') {
    component = <span className='greetingText'>
      {props.children}
    </span>
  }

  if(props.component === 'subGreeting') {
    component = <span className='subGreetingText'>
      {props.children}
    </span>
  }

  if(props.component === 'buttonText') {
    component = <span className='buttonText'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'label') {
    component = <span className='isLabel'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'text') {
    component = props.children;
  }

  if(props.component.toLowerCase() === 'caption') {
    component = <div className='isCaption'>
      {props.children}
    </div>
  }

  if(props.textAlign) {
    classNames.push(`textAlign__${props.textAlign}`);
  }

  if(props.wordWrap) {
    classNames.push(`wordWrap__${props.wordWrap}`);
  }

  if(props.animation) {
    classNames.push(`animation__${props.animation}`);
    classNames.push('animateInfinite');
  }

  if(responsive) {
    classNames.push(`isResponsive`);
  }

  if (linkTo) {
    classNames.push(`isLink`);

    if(linkType === 'eMail' ) {
      return (
        <a
        href={props.linkTo}
        onClick={() => track({
          eventValue: JSON.stringify({name: 'openEmailLink', value: props.linkTo}),
          eventName: `button_click_event`
        })}
        key={'linkTo'}
        className={classNames.join(' ')}
        >
          {component}
        </a>
      );
    }

    return <a
    href={props.linkTo}
    key={'linkTo'}
    onClick={() => track({
      eventValue: JSON.stringify({name: 'followLink', value: props.linkTo}),
      eventName: `button_click_event`
    })}
    
    className={classNames.join(' ')}
    target='_self'
    >
      {component}
    </a>;
  }

  return <div
    className={classNames.join(' ')}
  >
    {component}
  </div>;
}

export default Typography;
