import { Component } from 'react';

import superagent from 'superagent';

import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import Cloud from '../../Molecules/Cloud/cloud';
import Avatar from '../../Molecules/Avatar/avatar';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import Input from '../../Atoms/Input/input';
import Button from '../../Atoms/Button/button';

class SignUp extends Component {
  state = {
    language: 'en',
    responseBody: false,
    responseErrors: false,
    eMail: '',
    password: '',
    passwordConfirmation: '',
  }

  componentWillMount() {
    // this.getTokenFromQueryString();
  }

  clickButton() {
    const {
      REACT_APP_RECEIVER_API,
      REACT_APP_APP_NAME
    } = process.env;

    const {
      eMail,
      password,
      passwordConfirmation
    } = this.state;

    const postBody = {
      password: password,
      password_confirmation: passwordConfirmation,
      e_mail: eMail
    }

    superagent.post(`${REACT_APP_RECEIVER_API}/auth/sign-up`)
    .send(postBody)
    .end((err, res) => {
      if(err) {
        if (res.body.errors && res.body.errors.length > 0) {
          this.setState({
            responseErrors: res.body.errors
          });
        }

        console.log(err, res)
        console.log(res.body)
      }
      if(!err) {
        this.setState({
          responseErrors: false,
          responseBody: res.body
        });
      }
    });
  }

  render() {
    const {
      responseErrors,
      responseBody
    } = this.state;
    const returnErrors = [];
    const returnBody = [];

    // return <Loader></Loader>

    Object.keys(responseErrors).forEach(key => {
      returnErrors.push(
        <div
        style={{
          color:'red',
          float: 'none',
          clear: 'both',
          display: 'block',
          width: '100%',
          padding: '5px 0',
          textAlign: 'center'
        }}
        >
          {responseErrors[key]}
        </div>
      );
    });

    console.log(responseBody)
    if(responseBody.data) {
      returnBody.push(
        <Typography
          component='h5'
          textAlign='center'
        >
          Registered.<br/>
          <br/>
          Mail sent to: {responseBody.data[0].user_e_mail}
          <br/>
          Please also check your spam folder.<br/>
          <br/>
          {responseBody.data[0].activation_token_valid_for ? `The link is valid for ${responseBody.data[0].activation_token_valid_for}` : null}
        </Typography>
      );
    }

    Object.keys(responseBody).forEach(key => {
      returnBody.push(
        <div
        style={{
          color:'red',
          float: 'none',
          clear: 'both',
          display: 'block',
          width: '100%',
          padding: '5px 0',
          textAlign: 'center'
        }}
        >
          {responseErrors[key]}
        </div>
      );
    });
    
    return (
      <Grid
      wrapper
      >
        <Typography
          component='h1'
          textAlign='center'
          linkTo={`/${this.state.language}`}
        >
          <RotatingText
            animationName='colorFadePrimaryColor'
            defaultColor='primary'
            rotationSpeed={6000}
            staticText='|'
            staticTextSize={130}
            staticTextPosition='right'
            staticTextBlinking={true}
            words={['vomJulian']}
          />
        </Typography>      
        <Grid
          item
          columns={12}
        >
          <Grid
            item
            columns={4}
            spacing={20}
            responsiveFullWidth='800'
          >
            <Avatar/>
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            <Input
            defaultValue={this.state.eMail}
            placeholder='E-Mail'
            type='e-mail'
            onChange={(e) => this.setState({
              eMail: e.target.value
            })}
            />
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            <Input
            defaultValue={this.state.password}
            placeholder='Password'
            type='password'
            onChange={(e) => this.setState({
              password: e.target.value
            })}
            />
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            <Input
            defaultValue={this.state.passwordConfirmation}
            placeholder='Password confirmation'
            type='password'
            onChange={(e) => this.setState({
              passwordConfirmation: e.target.value
            })}
            />
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            <Button
            onClick={(e) => this.clickButton()}
            >
              Send
            </Button>
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            {returnErrors}
          </Grid>
          <Grid
            item
            columns={12}
            responsiveFullWidth='800'
          >
            {returnBody ? returnBody : null}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default SignUp;
