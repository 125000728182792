import './_square.scss';

function Square(props) {
  const {
    boxShadowHorizontalOffset,
    boxShadowVerticalOffset,
    boxShadowPosition,
    backgroundColor,
    boxShadowColor,
    animationDelay,
    boxShadowBlur,
    borderRight,
    boxShadow,
    children,
    animate
  } = props;
  const classNames = ['square'];

  if(borderRight) {
    classNames.push('borderRight');
  }

  if(boxShadow) {
    classNames.push('boxShadow');
  }

  if(animate) {
    classNames.push('animate');
  }

  classNames.push('animateInfinite');

  let boxShadowHOffset = 0;
  if(boxShadowHorizontalOffset) {
    boxShadowHOffset = boxShadowHorizontalOffset;
  }

  let boxShadowVOffset = 0;
  if(boxShadowVerticalOffset) {
    boxShadowVOffset = boxShadowVerticalOffset;
  }

  let boxShadowBlurValue = 0;
  if(boxShadowBlur) {
    boxShadowBlurValue = boxShadowBlur;
  }

  let boxShadowSpread = 0;

  let boxShadowColorValue = 'black';
  if(boxShadowColor) {
    boxShadowColorValue = boxShadowColor;
  }

  let boxShadowPositionValue = '';
  if(boxShadowPosition) {
    boxShadowPositionValue = boxShadowPosition;
  }

  let backgroundColorValue = 'transparent';
  if(backgroundColor) {
    backgroundColorValue = backgroundColor
  }

  const style = {
    animationDelay: animationDelay,
    backgroundColor: backgroundColorValue,
    boxShadow: `${boxShadowHOffset}px ${boxShadowVOffset}px ${boxShadowBlurValue}px` + 
               ` ${boxShadowSpread}px ${boxShadowColorValue} ${boxShadowPositionValue}` 
  }

  return <div
  className={classNames.join(' ')}
  style={style}
  >
    {children}
  </div>;
}

export default Square;
