import './_score.scss';

function Score(props) {
  const width = 100 / 10 * props.score;
  
  return <div
  className='score'
  >
    <div
    className='bar'
    style={{width: `${width}%`}}
    />
  </div>;
}

export default Score;
