import './_icon.scss';

import './_etl.scss';
import './_play.scss';
import './_flags.scss';
import './_check.scss';
import './_close.scss';
import './_pause.scss';
import './_hamburger.scss';
import './_arrowDown.scss';
import './_management.scss';
import './_consulting.scss';
import './_arrowRight.scss';
import './_development.scss';
import './_prototyping.scss';
import './_dataModelling.scss';
import './_reportDevelopment.scss';
import './_webDevelopment.scss';

function Icon(props) {
  const {
    icon,
    width,
    active
  } = props;

  const classNames = ['icon'];

  if(icon) {
    classNames.push(`icon-${icon}`);
  }

  if(width) {
    classNames.push(`width__${width}`);
  }

  if(active) {
    classNames.push(`active`);
  }

  return <div
  className={classNames.join(' ')}
  >
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
  </div>;
}

export default Icon;
