import './_input.scss';

function Input(props) {
  const {
    style,
    children,
    placeholder,
    onChange,
    type
  } = props;
  const classNames = ['input'];

  return <input
  className={classNames.join(' ')}
  style={style}
  type={type}
  placeholder={placeholder}
  onChange={onChange}
  >
    {children}
  </input>;
}

export default Input;
