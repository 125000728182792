import { Component } from 'react';
import queryString from 'query-string';
import { JaaSMeeting } from '@jitsi/react-sdk';

import Input from '../../Atoms/Input/input';
import Button from '../../Atoms/Button/button';
import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import RotatingText from '../../Molecules/RotatingText/rotatingText';

import translate from '../../../Utils/translate';
import translations from './translations.json';

class MeetingRooms extends Component {
  state = {
    enterRoom: false,
    token: ''
  }

  getParam(params, param) {
    const paramsObject = queryString.parse(params);

    if(paramsObject[param]) {
      return paramsObject[param];
    }

    return false;
  }

  getTokenFromQueryString() {
    if (
      window.location
      && window.location.search
    ) {

      const token = this.getParam(window.location.search, 'token');

      if(token) {
        this.setState({
          enterRoom: true,
          token: token
        })
      }
    }
  }

  componentWillMount() {
    this.getTokenFromQueryString();
  }

  render() {
    const {
      REACT_APP_RECEIVER_API,
      REACT_APP_jaas_8x8_vc__APP_ID,
      REACT_APP_APP_NAME
    } = process.env;
  
    const {
      enterRoom,
      token
    } = this.state;
    const {
      room,
      language
    } = this.props;

    return (
      <Grid
      wrapper
      >
        <Typography
          component='h1'
          textAlign='center'
        >
          <RotatingText
            defaultColor='primary'
            rotationSpeed={6}
            staticText='|'
            staticTextSize={130}
            noRotation={'true'}
            staticTextPosition='right'
            staticTextBlinking={true}
            words={['vomJulian']}
          />
        </Typography>
        { 
          !enterRoom ?
            <Grid
              item
              columns={12}
              spacing={20}
            >
              
              <Input
              onChange={(e) => this.setState({token: e.target.value})}
              placeholder='Enter Token'
              />
              <Button
              onClick={() => this.setState({enterRoom: true})}
              >
                Enter
              </Button>
            </Grid>
            : null
        }
        
            
        <Grid
          item
          columns={12}
          spacing={20}
          >
            {
              enterRoom ? <JaaSMeeting
                configOverwrite = {{
                  startWithAudioMuted: true,
                  hiddenPremeetingButtons: ['microphone'],
                  disableThirdPartyRequests: true,
                  disableLocalVideoFlip: true,
                  backgroundAlpha: 0,
                  breakoutRooms: {
                    hideAddRoomButton: true,
                    hideAutoAssignButton: true,
                    hideJoinRoomButton: true
                  },
                  brandingRoomAlias: room,
                  enableWelcomePage: true,
                  transcribingEnabled: false


                }}
                style = {{width: '100%'}}
                appId = { REACT_APP_jaas_8x8_vc__APP_ID }
                jwt = { token }
                roomName={room}
                devices = { true }

                onReadyToClose = { () => { window.location.reload() } }

                interfaceConfigOverwrite = {{
                    VIDEO_LAYOUT_FIT: 'both',
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4,
                    APP_NAME: REACT_APP_APP_NAME,
                    HIDE_DEEP_LINKING_LOGO: true,
                    SHOW_JITSI_WATERMARK: false,
                    SUPPORT_URL: 'vomjulian.com',
                    DISABLE_TRANSCRIPTION_SUBTITLES: true
                }}
        
                getIFrameRef = {
                  node => (
                    node.style.height = '800px',
                    node.style.width = '100%'
                  )
                
                }
              />
              
              : null
            }  
        </Grid>
        <Grid
          item
          columns={12}
          spacing={20}
        >
          <Typography component='span' textAlign='center' linkTo={`/${language}/imprint`}>
            {translate(translations, language, 'imprint')}
          </Typography>  
        </Grid>
      </Grid>
    );
  }
}

export default MeetingRooms;
