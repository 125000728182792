
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Switch,
  BrowserRouter,
  Route,
  Redirect
} from 'react-router-dom';
// import reportWebVitals from './reportWebVitals';

import './_index.scss';
import { track } from './Utils/track';

import FallBack from './Components/Pages/404/404';
import ActivateUser from './Components/Pages/Auth/activateUser';
import Imprint from './Components/Pages/Imprint/imprint';
import About from './Components/Pages/About/about';
import Home from './Components/Pages/Home/home';
import MeetingRooms from './Components/Pages/MeetingRooms/meetingRooms';
import SignUp from './Components/Pages/Auth/signUp';
import SignIn from './Components/Pages/Auth/signIn';

const TRACK_TIME = new Date();

const root = ReactDOM.createRoot(document.getElementById('page'));

const language = 'en';

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        {/* German Routes */}
        <Route exact path={`/de/about-me`}>
          <About language={'de'}/>
        </Route>
        <Route exact path={`/de/imprint`}>
          <Imprint language={'de'}/>
        </Route>
        <Route exact path={`/de`}>
          <Home language={'de'}/>
        </Route>

        {/* English Routes */}
        <Route exact path={`/en/about-me`}>
          <About language={'en'}/>
        </Route>
        <Route exact path={`/en/imprint`}>
          <Imprint language={'en'}/>
        </Route>
        <Route exact path={`/en`}>
          <Home language={'en'}/>
        </Route>

        {/* Meeting Room Routes */}
        <Route exact path={`/meeting-rooms/data-quality`}>
          <MeetingRooms room={'data-quality'} language='en'/>
        </Route>
        <Route exact path={`/meeting-rooms/giuliano-demontis`}>
          <MeetingRooms room={'giuliano-demontis'} language='en'/>
        </Route>
        <Route exact path={`/auth/activate-user/:activation_token`} component={ActivateUser}/>
        <Route exact path={`/auth/sign-up`} component={SignUp}/>
        <Route exact path={`/auth/sign-in`} component={SignIn}/>

        {/* Redirects */}
        <Redirect exact from='/imprint' to={`/${language}/imprint`} />
        <Redirect exact from='/about-me' to={`/${language}/about-me`} />
        <Redirect exact from='/about' to={`/${language}/about-me`} />
        <Redirect exact from='/' to={`/${language}`} />
        
        {/* Fallback */}
        <Route exact path="*">
          <FallBack language={language}/>
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);

const TRACK_TIME_END = new Date();
const diffTime = Math.abs(TRACK_TIME_END - TRACK_TIME);

track({applicationLoadtime: diffTime, eventName: 'view'})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
