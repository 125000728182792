import './_cloud.scss';

function Cloud(props) {
  return <div className='cloud'>
    <div className='cloudTopLeft'/>
    <div className='cloudTopRight'/>
    <div className='cloudCenter'>
      {props.children}
    </div>
    <div className='bubble1'/>
    <div className='bubble2'/>
    <div className='bubble3'/>
  </div>;
}

export default Cloud;
