import './_grid.scss';

function Grid(props) {
  const {
    responsiveFullWidth,
    flexDirection,
    justifyContent,
    borderBottom,
    alignItems,
    className,
    container,
    children,
    overflow,
    maxWidth,
    spacing,
    columns,
    wrapper,
    onClick,
    cursor,
    style,
    clear,
    type,
    item,
  } = props;
  const classNames = ['grid'];

  if (className) {
    classNames.push('container');
  }

  if (maxWidth) {
    classNames.push('maxWidth');
  }

  if (maxWidth) {
    classNames.push('container');
  }

  if (borderBottom) {
    classNames.push('borderBottom');
  }

  if (responsiveFullWidth) {
    classNames.push(`responsiveFullWidth__${responsiveFullWidth}`);
  }

  if (wrapper) {
    classNames.push('wrapper');
  }

  if (item) {
    classNames.push('item');
  }

  if (clear) {
    classNames.push('clear');
  }

  if (justifyContent) {
    classNames.push(`justifyContent__${justifyContent}`);
  }

  if (flexDirection) {
    classNames.push(`flexDirection__${flexDirection}`);
  }

  if (alignItems) {
    classNames.push(`alignItems__${alignItems}`);
  }

  if (cursor) {
    classNames.push(`cursor__${cursor}`);
  }

  if (overflow) {
    classNames.push(`overflow__${overflow}`);
  }

  if (type) {
    classNames.push(`type__${type}`);
  }

  if (spacing) {
    classNames.push(`spacing__${spacing}`);
  }

  if (columns) {
    classNames.push(`columns__${columns}`);
  }

  return (
    <div
    className={classNames.join(' ')}
    onClick={onClick}
    style={style}
    >
      {children}
    </div>
  );
}

export default Grid;
