import superagent from 'superagent';

export function track(parameters) {
    const {
      REACT_APP_RECEIVER_API,
      REACT_APP_APP_NAME
    } = process.env;


    let path = '';
    let domain = '';
    let eventValue = '';
    let browserName = '';
    let operatingSystem = '';

    if (parameters.eventValue) {
      eventValue = parameters.eventValue
    }
    
    if (window.navigator) {
      browserName = window.navigator.vendor
    }

    if (
      window.navigator
      && window.navigator.userAgentData
    ) {
      operatingSystem = window.navigator.userAgentData.platform
    }

    if (
      window.location
      && window.location.origin
    ) {
      domain = window.location.origin;
    }

    if (
      window.location
      && window.location.pathname
    ) {
      path = window.location.pathname;
    }

    let applicationLoadtime = 0;
    if(parameters.applicationLoadtime) {
      applicationLoadtime = parameters.applicationLoadtime;
    }

    let eventName = null;
    if(parameters.eventName) {
      eventName = parameters.eventName;
    }

    let windowWidth = 0;
    if (
      window.innerWidth
    ) {
      windowWidth = window.innerWidth;
    }

    let windowHeigth = 0;
    if (
      window.innerHeight
    ) {
      windowHeigth = window.innerHeight;
    }

    const info = {
      producers_dp_producer_ips: 'IPs are not transported',
      producers_dp_producer_names: REACT_APP_APP_NAME,

      urls_dp_url_domains: domain,
      urls_dp_url_pathes: path,

      events_dp_event_names: eventName,
      events_dp_event_values: eventValue,

      browsers_dp_browser_names: browserName,

      windows_dp_window_widths: windowWidth,
      windows_dp_window_heights: windowHeigth,

      operatingsystems_dp_operatingsystem_platforms: operatingSystem,

      metrics_dp_posted_ats: new Date().toISOString(),
      metrics_dp_application_loadtimes: applicationLoadtime
    };

    // console.log(window);

    // superagent.post(`${REACT_APP_RECEIVER_API}/v-1-dp-web-dp-metrics`)
    // .send(info)
    // .end((err, res) => {

    // });
}
