import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import SwitchLanguage from '../../Molecules/SwitchLanguage/switchLanguage';
import GoldenRatioBox from '../../Organisms/GoldenRatioBox/goldenRatioBox';

import './_home.scss';

import translations from './translations.json';
import translate from '../../../Utils/translate';

function Home(props) {
  const language = props.language;

  return (
    <Grid
      wrapper
      className='home'
    >
      <Grid
        item
        columns={12}
      >
        <Typography
          component='h1'
          textAlign='center'
          linkTo={`/${language}`}
        >
          <RotatingText
            // animationName='colorFadePrimaryColor'
            defaultColor='primary'
            rotationSpeed={60000}
            staticText='|'
            staticTextSize={130}
            staticTextPosition='right'
            staticTextBlinking={true}
            words={['vomJulian']}
          />
        </Typography>
        <Typography component='h4' textAlign='center'>
          {translate(translations, language, 'business_intelligence_consultancy')}<br/>
          & {translate(translations, language, 'data_driven_development')}
        </Typography>
      </Grid>
      <Grid
        item
        columns={12}
      >
        <GoldenRatioBox language={language}/>
      </Grid>
      <Grid
        item
        columns={12}
      >
        <SwitchLanguage language={language}/>
      </Grid>
    </Grid>
    
  );
}

export default Home;
