import { Component } from 'react';
import { track } from '../../../Utils/track';

import './_goldenRatioBox.scss';
import './_controller.scss';
import './_joystick.scss';

import SquareText from '../../Atoms/SquareText/squareText';
import Typography from '../../Atoms/Typography/typography';
import Rectangle from '../../Atoms/Rectangle/rectangle';
import Circle from '../../Atoms/Circle/circle';
import Square from '../../Atoms/Square/square';
import Icon from '../../Atoms/Icon/icon';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import Grid from '../../Molecules/Grid/grid';

import translations from './translations.json';
import translate from '../../../Utils/translate';

class GoldenRatioBox extends Component {
  state = {
    controlElementsActive: false,
    showBorders: false,
    boxShadow: true,
    boxShadowHorizontalOffset: 0,
    boxShadowVerticalOffset: 0,
    boxShadowBlur: 0,
    boxShadowColor: 'rgb(0 0 0 / 60%)',
    boxShadowPosition: '',
    animate: false,
    nautilusColor: 'lightskyblue',
    squareColor: 'lightskyblue',
    
    language: 'de',

    trackMousePosition: false,
    pageMiddle: false,
    mousePositionX: 0,
    mousePositionY: 0,

    windowInnerHeight: 0,
    windowInnerWidth: 0,

    joyStickDiameter: 120,
    joyStickInnerStickDiameter: 40,
  }


  componentDidMount() {
    this.setState({
      language: this.props.language
    });
  }

  // componentDidMount() {
  //   const joyStickContainer = document.getElementById('joyStickContainer');

  //   joyStickContainer.addEventListener('touchmove', function (e){
  //       e.preventDefault();
  //   }, false);
  // }

  _onMouseMove(e) {
    const {
      type
    } = e;
    const {
      joyStickInnerStickDiameter
    } = this.state;

    if (this.state.trackMousePosition) {
      let mousePositionX = 0;
      let mousePositionY = 0;
      let boxShadowHorizontalOffset = 0;
      let boxShadowVerticalOffset = 0;

      if(type === 'touchmove') {
        mousePositionX = e.touches[0] ? e.touches[0].pageX : 0;
        mousePositionY = e.touches[0] ? e.touches[0].pageY : 0;
        boxShadowHorizontalOffset = this.calculateBoxShadowPositionLeft(e.touches[0] ? e.touches[0].pageX : 0, this.state.windowInnerWidth);
        boxShadowVerticalOffset = this.calculateBoxShadowPositionTop(e.touches[0] ? e.touches[0].pageY : 0, this.state.windowInnerHeight);
      }

      if(type === 'mousemove') {
        mousePositionX = e.pageX ? e.pageX : 0;
        mousePositionY = e.pageY ? e.pageY : 0;
        boxShadowHorizontalOffset = this.calculateBoxShadowPositionLeft(e.pageX, this.state.windowInnerWidth);
        boxShadowVerticalOffset = this.calculateBoxShadowPositionTop(e.pageY, this.state.windowInnerHeight);
      }

      if(['mousemove', 'touchmove'].includes(type)) {
        this.setState({
          mousePositionX,
          mousePositionY,
          boxShadowHorizontalOffset,
          boxShadowVerticalOffset,
        });
      }
    }
  }

  _mouseDownJoystick(event) {
    if (event.type === "mousedown") {
      this.setState({
        boxShadowBlur: 15,
        trackMousePosition: true,
        controlElementsActive: false,
        windowInnerWidth: event.view.innerWidth,
        windowInnerHeight: event.view.innerHeight,
      });
    }

    if (event.type === "touchstart") {
      this.setState({
        boxShadowBlur: 15,
        trackMousePosition: true,
        controlElementsActive: false,
        windowInnerWidth: event.view.innerWidth,
        windowInnerHeight: event.view.innerHeight,
      });
    }

    if (event.type === "mouseup") {
      this.setState({
        trackMousePosition: false,
        pageMiddle: false,
      });
    }
  }

  _mouseLeave(event){
    // if (event.type === "mousedown") {
    //   this.setState({
    //     boxShadowBlur: 15,
    //     trackMousePosition: false,
    //     pageMiddle: false,
    //     windowInnerWidth: event.view.innerWidth,
    //     windowInnerHeight: event.view.innerHeight,
    //   });
    // }

    // console.log(event.type)

    // if (event.type === "touchstart") {
    //   this.setState({
    //     boxShadowBlur: 15,
    //     trackMousePosition: false,
    //     pageMiddle: false,
    //     windowInnerWidth: event.view.innerWidth,
    //     windowInnerHeight: event.view.innerHeight,
    //   });
    // }

    // if (event.type === "mouseup") {
    //   this.setState({
    //     trackMousePosition: false,
    //     pageMiddle: false,
    //   });
    // }
  }

  calculateJoystickPositionLeft(mousePositionX, windowInnerWidth) {
    const {
      joyStickInnerStickDiameter,
      joyStickDiameter,
    } = this.state;
    const radius = joyStickDiameter / 2;
    const innerStickWidth = joyStickInnerStickDiameter / 2;
    const position = ((parseFloat(mousePositionX) - (parseFloat(windowInnerWidth)/ 2)) + radius - innerStickWidth);
    let returnPosition = position;

    // if(position <= 0) { 
    //   returnPosition = 0;
    // }

    // if(position >= (joyStickDiameter- joyStickInnerStickDiameter)) { 
    //   returnPosition = (joyStickDiameter- joyStickInnerStickDiameter);
    // }

    return returnPosition;
  }

  calculateJoystickPositionTop(mousePositionY, windowInnerHeight) {
    const {
      joyStickInnerStickDiameter,
      joyStickDiameter,
    } = this.state;
    const radius = (joyStickDiameter / 2);
    const innerStickDiameter = joyStickInnerStickDiameter;
    const innerStickRadius = joyStickInnerStickDiameter / 2;
    let calculatedPosition = (parseFloat(mousePositionY) - parseFloat(windowInnerHeight));

    // if ((calculatedPosition + innerStickDiameter) >= (innerStickRadius)) {
    //   calculatedPosition = (innerStickRadius * -1);
    // }

    // if ((joyStickInnerStickDiameter - innerStickDiameter) >= (calculatedPosition + radius + innerStickDiameter)) {
    //   calculatedPosition = joyStickInnerStickDiameter - innerStickDiameter - radius - innerStickDiameter;
    // }

    return (calculatedPosition + radius + innerStickDiameter);
  }

  calculateBoxShadowPositionTop(mousePositionY, windowInnerHeight) {
    const {
      joyStickInnerStickDiameter,
      joyStickDiameter
    } = this.state;
    const radius = joyStickDiameter / 2;
    const innerStickWidth = joyStickInnerStickDiameter / 2;

    return (parseFloat(mousePositionY) - parseFloat(windowInnerHeight) + radius) / 2;
  }

  calculateBoxShadowPositionLeft(mousePositionX, windowInnerWidth) {
    return (parseFloat(mousePositionX) - (parseFloat(windowInnerWidth) / 2)) / 2;
  }

  joyStick() {
    const {
      joyStickInnerStickDiameter,
      trackMousePosition,
      windowInnerHeight,
      joyStickDiameter,
      windowInnerWidth,
      mousePositionX,
      mousePositionY,
      pageMiddle,
    } = this.state;
    const classNamesJoystick = ['joyStick'];

    if (trackMousePosition) {
      classNamesJoystick.push('active');
    }

    return <div
      id='joyStickContainer'
      className={classNamesJoystick.join(' ')}
      ref={el => {
        if (!el) return;

        if (!pageMiddle) {
          this.setState({
            pageMiddle: (el.getBoundingClientRect().x + (el.getBoundingClientRect().width / 2))
          });
        }
      }}
    >

      {trackMousePosition ?
        <div
          onClick={(e) => this.setState({
            controlElementsActive: false,
            trackMousePosition: false
          })}
          className='closeJoystick'
        >
          <Icon icon='close'/>
        </div> : null}
      <div
        className='stick'
        onMouseDown={e => {this._mouseDownJoystick(e)}}
        onMouseUp={e => {this._mouseDownJoystick(e)}}
        // onMouseLeave={e => {this._mouseLeave(e)}}
        onTouchStart={e => {this._mouseDownJoystick(e)}}
        onTouchMove={e => { this._onMouseMove(e) }}
        onTouchEnd={e => e.preventDefault()}
        onMouseMove={e => { this._onMouseMove(e) }}
        style={{
          width: `${joyStickDiameter}px`,
          height: `${joyStickDiameter}px`,
          boxShadow: 
            `${this.calculateJoystickPositionLeft(mousePositionX, windowInnerWidth) - 40}px ${this.calculateJoystickPositionTop(mousePositionY, windowInnerHeight)  - 40}px 20px inset #131313bd,` +
            `${this.calculateJoystickPositionLeft(mousePositionX, windowInnerWidth) - 40}px ${this.calculateJoystickPositionTop(mousePositionY, windowInnerHeight)  - 40}px 30px inset #131313bd`,
        }}
      >
        {!trackMousePosition ? 
          <Typography
            component='buttonText'
            textAlign='center'
            animation='glow'
          >
            Drück mich
          </Typography> : null}
          {
            trackMousePosition ? <div className='innerStick' style={{
              top: `${this.calculateJoystickPositionTop(mousePositionY, windowInnerHeight) === 0 ?
                joyStickInnerStickDiameter : this.calculateJoystickPositionTop(mousePositionY, windowInnerHeight)}px`,
              left: `${this.calculateJoystickPositionLeft(mousePositionX, windowInnerWidth) === 0 ?
                joyStickInnerStickDiameter : this.calculateJoystickPositionLeft(mousePositionX, windowInnerWidth)}px`
            }}/> : null
          }
      </div>      
    </div>;
  }

  controller() {
    const {
      controlElementsActive,
      boxShadowHorizontalOffset,
      boxShadowVerticalOffset,
      boxShadowColor,
      boxShadowBlur,
      showBorders,
      language,
      animate
    } = this.state;

    const classNamesController = ['controller'];
    const classNamesMenu = ['menu'];
    const classNamesControlElements = ['controlElements'];
    if (controlElementsActive) {
      classNamesController.push('open');
    }

    return <div className={classNamesController.join(' ')}>
      <div
        className={classNamesMenu.join(' ')}
      >
        <div
          onClick={() => this.setState({
            controlElementsActive: !this.state.controlElementsActive,
            trackMousePosition: false
          }, () =>
            track({
              eventValue: JSON.stringify({name: `${this.state.controlElementsActive ? 'open' : 'close'}GoldenRatioSettings`, value: `Clicked ${this.state.controlElementsActive ? 'Open' : 'Close'} Golden Ratio Settings`}),
              eventName: `button_click_event`
            })
          )}
        >
          <Icon icon={controlElementsActive ? 'arrowDown' : 'hamburger'} />
        </div>
      </div>


      <div className={classNamesControlElements.join(' ')}>

        <Grid
          wrapper
          spacing={50}
          overflow='scrollY' 
          type='card'
        >
          
          <Grid
            item
            columns={6}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'borders')}</Typography>
            <div
              onClick={
                () => this.setState({
                  showBorders: !showBorders
                })
              }
            >
              <Typography
                component='span'
              >
                <Icon icon={showBorders ? 'close' : 'check'} />
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            columns={6}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'animations')}</Typography>
            <div
              onClick={
                () => this.setState({
                  animate: !animate
                }, () => track({
                  eventValue: JSON.stringify({name: `${animate ? 'StopAnimation' : 'StartAnimation'}GoldenRatioBox`, value: `Clicked the ${animate ? 'Stop' : 'Start'} Animation Button from Golden Ratio Box Settings`}),
                  eventName: `button_click_event`
                }))
              }
            >
              <Typography
                component='span'
              >
                <Icon icon={animate ? 'pause' : 'play'} />
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            columns={12}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'nautilus_color')}</Typography>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, nautilusColor: 'pink' })}
            >
              <Square
                animate={false}
                backgroundColor={'pink'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              onClick={() => this.setState({ animate: false, nautilusColor: 'transparent' })}
              cursor='pointer'
            >
              <Square
                animate={false}
                backgroundColor={'transparent'}
              >
                <Typography component='label'>
                  {translate(translations, language, 'none')}
                </Typography>
              </Square>
            </Grid>

            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, nautilusColor: 'gold' })}
            >
              <Square
                animate={false}
                backgroundColor={'gold'}
              />
            </Grid>

            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, nautilusColor: 'rgb(0 0 0 / 60%)' })}
            >
              <Square
                animate={false}
                backgroundColor={'rgb(0 0 0 / 60%)'}
              />
            </Grid>

            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, nautilusColor: 'lightskyblue' })}
            >
              <Square
                animate={false}
                backgroundColor={'lightskyblue'}
              />
            </Grid>
          </Grid>

          <Grid
            item
            columns={12}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'square_color')}</Typography>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, squareColor: 'pink' })}
            >
              <Square
                animate={false}
                backgroundColor={'pink'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={20}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, squareColor: 'transparent' })}
            >
              <Square
                animate={false}
                backgroundColor={'transparent'}
              >
                <Typography component='label'>
                  {translate(translations, language, 'none')}
                </Typography>
              </Square>
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, squareColor: 'gold' })}
            >
              <Square
                animate={false}
                backgroundColor={'gold'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, squareColor: 'rgb(0 0 0 / 60%)' })}
            >
              <Square
                animate={false}
                backgroundColor={'rgb(0 0 0 / 60%)'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ animate: false, squareColor: 'lightskyblue' })}
            >
              <Square
                animate={false}
                backgroundColor={'lightskyblue'}
              />
            </Grid>
          </Grid>

          <Grid
            item
            columns={12}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'box_shadow_position')}</Typography>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowBlur: 15, boxShadowPosition: 'inset' })}
            >
              <Square
                animate={false}
                backgroundColor={'transparent'}
                boxShadowColor={boxShadowColor}
                boxShadowPosition='inset'
                boxShadowBlur={20}
                boxShadow={true}
                boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                boxShadowVerticalOffset={boxShadowVerticalOffset}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowBlur: 15, boxShadowPosition: '' })}
            >
              <Square
                animate={false}
                boxShadow={true}
                boxShadowColor={boxShadowColor}
                boxShadowBlur={20}
                boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                boxShadowVerticalOffset={boxShadowVerticalOffset}
                boxShadowPosition=''
              />
            </Grid>
            <Grid clear />
          </Grid>
          <Grid
            item
            columns={12}
            spacing={20}
          >
            <Typography component='h3'>{translate(translations, language, 'box_shadow_color')}</Typography>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowColor: 'pink' })}
            >
              <Square
                animate={false}
                backgroundColor={'pink'}
              />
            </Grid>
            <Grid
              item              
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowColor: 'transparent' })}
            >
              <Square
                animate={false}
                backgroundColor={'transparent'}
              >
                <Typography component='label'>
                  {translate(translations, language, 'none')}
                </Typography>
              </Square>
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowColor: 'gold' })}
            >
              <Square
                animate={false}
                backgroundColor={'gold'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowColor: 'rgb(0 0 0 / 60%)' })}
            >
              <Square
                animate={false}
                backgroundColor={'rgb(0 0 0 / 60%)'}
              />
            </Grid>
            <Grid
              item
              columns={1}
              spacing={10}
              cursor='pointer'
              onClick={() => this.setState({ boxShadowColor: 'lightskyblue' })}
            >
              <Square
                animate={false}
                backgroundColor={'lightskyblue'}
              />
            </Grid>
            <Grid clear />
          </Grid>
          <Grid clear />
        </Grid>
      </div>
      <Grid
        clear
      />
    </div>;
  }

  render() {
    const {
      boxShadowHorizontalOffset,
      boxShadowVerticalOffset,
      controlElementsActive,
      boxShadowPosition,
      boxShadowColor,
      nautilusColor,
      boxShadowBlur,
      squareColor,
      showBorders,
      boxShadow,
      language,
      animate
    } = this.state;
    let borderLeft = false;
    let borderRight = false;
    let borderTop = false;
    let borderBottom = false;
    const classNames = ['goldenRatioBox'];

    if (showBorders) {
      borderLeft = true;
      borderRight = true;
      borderTop = true;
      borderBottom = true;
      classNames.push('allBorders');
    }

    return <>
      {this.controller()}

      <section className={classNames.join(' ')}>
        <Square
          animate={animate}
          animationDelay='250ms'
          backgroundColor={squareColor}
          boxShadow={boxShadow}
          boxShadowBlur={boxShadowBlur}
          boxShadowColor={boxShadowColor}
          boxShadowHorizontalOffset={boxShadowHorizontalOffset}
          boxShadowVerticalOffset={boxShadowVerticalOffset}
          boxShadowPosition={boxShadowPosition}
        >
          <Circle
            animate={animate}
            circleColor={nautilusColor}
          />

          <SquareText
            component='h4'
            responsive={true}
            cursor
            linkTo={`/${language}/about-me`}
          >
            <RotatingText
              words={[translate(translations, language, 'about_me')]}
              defaultColor='primary'
            />
          </SquareText>

        </Square>
        <Rectangle rotated borderLeft={borderLeft}>
          <Square
            animate={animate}
            backgroundColor={squareColor}
            animationDelay='300ms'
            boxShadow={boxShadow}
            boxShadowBlur={boxShadowBlur}
            boxShadowColor={boxShadowColor}
            boxShadowHorizontalOffset={boxShadowHorizontalOffset}
            boxShadowVerticalOffset={boxShadowVerticalOffset}
            boxShadowPosition={boxShadowPosition}
          >
            <Circle
              animate={animate}
              circleColor={nautilusColor}
              circleOffsetLeft={'-100%'}
            />

            <SquareText
              component='h4'
              responsive={true}
              cursor
              linkType='eMail'
              linkTo={`mailto:giuliano.demontis@vomjulian.com?subject=Hey vomJulian!&body=Hey vomJulian!`}
            >
              <RotatingText
                defaultColor='transparent'
                words={[translate(translations, language, 'contact')]}
                animationName='colorFadePrimaryColor'
              />
            </SquareText>

          </Square>
          <Rectangle borderTop={borderTop}>
            <Rectangle rotated borderRight={borderRight}>
              <Rectangle borderBottom={borderBottom}>
                <Square
                  animate={animate}
                  animationDelay='450ms'
                  backgroundColor={squareColor}
                  boxShadow={boxShadow}
                  boxShadowBlur={boxShadowBlur}
                  boxShadowColor={boxShadowColor}
                  boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                  boxShadowVerticalOffset={boxShadowVerticalOffset}
                  boxShadowPosition={boxShadowPosition}
                >
                  <Circle
                    animate={animate}
                    circleColor={nautilusColor}
                  />
                </Square>
                <Rectangle rotated borderLeft={borderLeft}>
                  <Square
                    animate={animate}
                    animationDelay='500ms'
                    boxShadow={boxShadow}
                    boxShadowBlur={boxShadowBlur}
                    boxShadowColor={boxShadowColor}
                    boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                    boxShadowVerticalOffset={boxShadowVerticalOffset}
                    boxShadowPosition={boxShadowPosition}
                    backgroundColor={squareColor}
                  >
                    <Circle
                      animate={animate}
                      circleColor={nautilusColor}
                      circleOffsetLeft={'-100%'}
                    />
                  </Square>
                  <Rectangle borderTop={borderTop}>
                    <Rectangle rotated borderRight={borderRight}>
                      <Rectangle borderBottom={borderBottom}>
                        <Square
                          animationDelay='650ms'
                          borderRight={borderRight}
                          boxShadow={boxShadow}
                          boxShadowBlur={boxShadowBlur}
                          boxShadowColor={boxShadowColor}
                          boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                          boxShadowVerticalOffset={boxShadowVerticalOffset}
                          boxShadowPosition={boxShadowPosition}
                          backgroundColor={squareColor}
                          animate={animate}
                        >
                          <Circle
                            animate={animate}
                            circleColor={nautilusColor}
                          />
                        </Square>
                        <Square
                          animate={animate}
                          animationDelay='700ms'
                          backgroundColor={squareColor}
                          boxShadowColor={boxShadowColor}
                          boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                          boxShadowVerticalOffset={boxShadowVerticalOffset}
                          boxShadowPosition={boxShadowPosition}
                          boxShadow={boxShadow}
                          boxShadowBlur={boxShadowBlur}
                        >
                          <Circle
                            animate={animate}
                            circleColor={nautilusColor}
                            circleOffsetLeft={'-100%'}
                          />
                        </Square>
                      </Rectangle>
                      <Square
                        animate={animate}
                        animationDelay='600ms'
                        boxShadow={boxShadow}
                        boxShadowColor={boxShadowColor}
                        boxShadowBlur={boxShadowBlur}
                        backgroundColor={squareColor}
                        boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                        boxShadowVerticalOffset={boxShadowVerticalOffset}
                        boxShadowPosition={boxShadowPosition}
                      >
                        <Circle
                          animate={animate}
                          circleColor={nautilusColor}
                          circleOffsetTop={'-100%'}
                        />
                      </Square>
                    </Rectangle>
                    <Square
                      animate={animate}
                      animationDelay='550ms'
                      boxShadow={boxShadow}
                      boxShadowBlur={boxShadowBlur}
                      boxShadowColor={boxShadowColor}
                      boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                      boxShadowVerticalOffset={boxShadowVerticalOffset}
                      boxShadowPosition={boxShadowPosition}
                      backgroundColor={squareColor}
                    >
                      <Circle
                        animate={animate}
                        circleColor={nautilusColor}
                        circleOffsetTop={'-100%'}
                        circleOffsetLeft={'-100%'}
                      />
                    </Square>
                  </Rectangle>
                </Rectangle>
              </Rectangle>
              <Square
                animate={animate}
                animationDelay='400ms'
                boxShadow={boxShadow}
                boxShadowBlur={boxShadowBlur}
                boxShadowColor={boxShadowColor}
                boxShadowHorizontalOffset={boxShadowHorizontalOffset}
                boxShadowVerticalOffset={boxShadowVerticalOffset}
                boxShadowPosition={boxShadowPosition}
                backgroundColor={squareColor}
              >
                <Circle
                  animate={animate}
                  circleColor={nautilusColor}
                  circleOffsetTop={'-100%'}
                />
              </Square>
            </Rectangle>
            <Square
              animate={animate}
              animationDelay='350ms'
              boxShadow={boxShadow}
              boxShadowBlur={boxShadowBlur}
              boxShadowColor={boxShadowColor}
              boxShadowHorizontalOffset={boxShadowHorizontalOffset}
              boxShadowVerticalOffset={boxShadowVerticalOffset}
              boxShadowPosition={boxShadowPosition}
              backgroundColor={squareColor}
            >
              <Circle
                animate={animate}
                circleColor={nautilusColor}
                circleOffsetTop={'-100%'}
                circleOffsetLeft={'-100%'}
              />

              <SquareText
                component='h5'
                cursor
                linkTo={`/${language}/imprint`}
                responsive={true}
              >
                <RotatingText
                  defaultColor='primary'
                  words={[translate(translations, language, 'imprint')]}
                />
              </SquareText>
            </Square>
          </Rectangle>
        </Rectangle>
      </section>

      {/* <Grid
        item
        columns={12}
        spacing={20}
      >
        {this.joyStick()}
      </Grid> */}
    </>;
  }
}

export default GoldenRatioBox;
