import './_rectangle.scss';

function Rectangle(props) {
  const {
    borderBottom,
    borderRight,
    borderLeft,
    borderTop,
    children,
    rotated
  } = props;

  const classNames = ['rectangle'];

  if(rotated) {
    classNames.push('rotated');
  }

  if(borderLeft) {
    classNames.push('borderLeft');
  }

  if(borderRight) {
    classNames.push('borderRight');
  }

  if(borderTop) {
    classNames.push('borderTop');
  }

  if(borderBottom) {
    classNames.push('borderBottom');
  }

  return <div
  className={classNames.join(' ')}
  >
    {children}
  </div>;
}

export default Rectangle;
