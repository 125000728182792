import { Link } from 'react-router-dom';
import './_button.scss';

function Button(props) {
  const {
    style,
    children,
    onClick,
    buttonType,
    linkTo
  } = props;
  const classNames = ['button'];

  if (buttonType) {
    classNames.push(`buttonType__${buttonType}`)
  }

  let returnArray = [
    <button
    key={`button__${Math.random()}`}
    style={style}
    className={classNames.join(' ')}
    onClick={onClick}
    >
      {children}
    </button>
  ]

  if(linkTo) {
    returnArray = [
      <Link
      style={{width: '100%'}}
      key={`buttonLink__${Math.random()}`}
      to={linkTo}
      >
        {returnArray}
      </Link>
    ]
  }

  return returnArray;
}

export default Button;
