import { Component } from 'react';

import superagent from 'superagent';

import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import Avatar from '../../Molecules/Avatar/avatar';
import RotatingText from '../../Molecules/RotatingText/rotatingText';
import Button from '../../Atoms/Button/button';

class ActivateUser extends Component {
  state = {
    language: 'en',
    activation_token: false,
    responseBody: {},
    responseError: false,
    user: {},
    refresh_token: false,
    access_token: false
  }

  getTokenFromQueryString() {
    const {
      REACT_APP_RECEIVER_API,
      REACT_APP_APP_NAME
    } = process.env;
    const access_token_from_local_storage = localStorage.getItem("access_token");
    const refresh_token_from_local_storage = localStorage.getItem("access_token");
    if (
      (
        this.props.match
        && this.props.match.params
      ) || access_token_from_local_storage

    ) {

      const {activation_token} = this.props.match.params;

      if(activation_token && !access_token_from_local_storage) {
        superagent
        .get(`${REACT_APP_RECEIVER_API}/auth/activate-user/${activation_token}`)
        // .query({ action: 'edit', city: 'London' }) // query string
        // .use(prefix) // Prefixes *only* this request
        // .use(nocache) // Prevents caching of *only* this request
        .end((err, res) => {
          if(res && res.body && res.body.data && res.body.data.tokens) {
            const {
              access_token,
              refresh_token
            } = res.body.data.tokens;

            if (access_token) {
              localStorage.setItem("access_token", access_token);
              localStorage.setItem("refresh_token", refresh_token);

              const explode_token = access_token.split('.');

              let user = {}
              try {
                user = atob(explode_token[1]);
                user = JSON.parse(atob(explode_token[1]));
              } catch(err) {
                console.log(err);
              }

              this.setState({
                access_token,
                refresh_token,
                user
              });
            }
          }

          if(err) {
            this.setState({
              responseBody: res && res.body ? res.body : false,
              responseError: err && err.message ? err.message : false,
              activation_token: activation_token
            });
          }
        });
      }

      if (access_token_from_local_storage) {
        const explode_token = access_token_from_local_storage.split('.');

        let user = {}
        try {
          user = atob(explode_token[1]);
          user = JSON.parse(atob(explode_token[1]));
        } catch(err) {
          console.log(err);
        }

        this.setState({
          access_token: access_token_from_local_storage,
          refresh_token: refresh_token_from_local_storage,
          user
        });
      }
    }
  }

  componentWillMount() {
    this.getTokenFromQueryString();
  }

  render() {
    const return_roles = [];
    if (this.state.user && this.state.user.roles) {
      const {
        roles
      } = this.state.user;

      Object.keys(roles).forEach(key => {
        return_roles.push(roles[key].role)
      });
    }

    return (
      <Grid
      wrapper
      >
        <Typography
          component='h1'
          textAlign='center'
          linkTo={`/${this.state.language}`}
        >
          <RotatingText
            animationName='colorFadePrimaryColor'
            defaultColor='primary'
            rotationSpeed={6000}
            staticText='|'
            staticTextSize={130}
            staticTextPosition='right'
            staticTextBlinking={true}
            words={['vomJulian']}
          />
        </Typography>      
        <Grid
          item
          columns={12}
        >
          <Grid
            item
            columns={4}
            spacing={20}
            responsiveFullWidth='800'
          >
            <Avatar/>
          </Grid>
          <Grid
            item
            columns={4}
            responsiveFullWidth='800'
          >
            { this.state.responseBody.errors ? 
              <Typography
              component='h2'
              textAlign='center'
              style={{color:'red'}}
              >
                {this.state.responseBody.errors}
                <Typography
                component='caption'
                textAlign='center'
                animation='pulsate'
                linkTo={`/${this.state.language}`}
                >
                  Go to the Home Page
                </Typography>
              </Typography> : null }
            {
              this.state.responseBody.data
              && this.state.responseBody.data.message ? 
              <Typography
              component='h2'
              textAlign='center'
              >
                {this.state.responseBody.data.message}
              </Typography> : null }
            {
              Object.keys(this.state.user).length > 0 ? 
              <>
                <Typography
                component='h2'
                textAlign='center'
                >
                  Welcome {this.state.user.e_mail ? this.state.user.e_mail : ''}
                </Typography>
                <Typography
                component='h3'
                textAlign='center'
                >
                  You are a {return_roles ? return_roles.join(', and ') : ''} user.
                </Typography>
              </> : null }
            { this.state.responseError ? 
              <Typography
              component='h2'
              textAlign='center'
              >
                {this.state.responseError}
              </Typography> : null }
          </Grid>
          {
            this.state.access_token && this.state.refresh_token ?
              <Grid
                item
                columns={10}
                responsiveFullWidth='800'
              >
                <Button
                linkTo="/user/profile"
                >
                  Go to your Profile
                </Button>
              </Grid> 
            : null
          }
          {
            this.state.access_token && this.state.refresh_token ?
              <Grid
                item
                columns={10}
                responsiveFullWidth='800'
                style={{margin: '10px 0 0'}}
              >
                <Button
                  buttonType='warn'
                  onClick={(e) => {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    this.setState({
                      responseBody: false,
                      responseErrors: false,
                      access_token: false,
                      refresh_token: false,
                      user: false
                    });
                  }}
                >
                  Sign Out
                </Button>
              </Grid> 
            : null
          }
        </Grid>
      </Grid>
    );
  }
}

export default ActivateUser;
